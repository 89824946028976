import React, { useState, useEffect } from "react";
import { Modal, Button, notification, Form, Input, Select } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const EditCategoryModal = ({ category, show, onHide, onSave, fetchData, viewOnly }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (show) {
      form.setFieldsValue(category);
    }
  }, [show, category, form]);
  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const response = await axios.post(
        "http://68.178.169.226:12100/api/v1/member/edit",
        { ...category, ...values },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 401 || response.status === 404) {
        return;
      }

      if (response.data.success) {
        console.log("Category updated successfully");
        onSave(values);
        onHide();
      } else {
        console.error("Failed to update category");
      }

      setLoading(false);
      notification.success({
        message: "Member details updated successfully.",
      });
      fetchData();
      onHide();
    } catch (error) {
      console.error("Error updating category:", error);
      setLoading(false);
    }
  };

  return (
    <Modal
    title={viewOnly ? "View Voter" : "Edit Voter"}
      visible={show}
      onCancel={onHide}
      footer={
        !viewOnly &&[
        <Button key="cancel" onClick={onHide}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={loading}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSaveChanges} initialValues={category} layout="vertical">
        <Form.Item name="clientid" style={{ display: "none" }}>
          <Input type="text" disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="Name" name="firstname">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="Father Name" name="fathername">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="DOB" name="dob">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="UID" name="uid">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item name="gender" label="Gender">
        <Select disabled={viewOnly}>
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
            <Option value="Others">Others</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Address" name="address">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="MP Area" name="mparea">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="MLA Area" name="mlaarea">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="Occupation" name="occupation">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="City" name="city">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="State" name="state">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="Pincode" name="pincode">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="Married" name="married">
          <Input disabled={viewOnly} />
        </Form.Item>
        <Form.Item label="Spousename" name="spousename">
          <Input disabled={viewOnly} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCategoryModal;
