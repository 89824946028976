import React, { useState, useEffect   } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import "./App.css";
import axios from "axios";

const AdminLogin = () => {
    const [mobile, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    const token = "";
    // const [logoUrl, setLogoUrl] = useState(''); 
    const handleLogin = async () => {
      try {
        // Step 1: Authenticate the user
        const response = await fetch('http://68.178.169.226:12100/api/v1/login/authenticate', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: mobile, password }),
        });
    
        if (response.ok) {
          const data = await response.json();
          const token = data.token;
    
          // Store the token in localStorage
          localStorage.setItem("token", token);
    
          // Step 2: Fetch user details using the token
          await fetchUserDetails(token);
    
          // Navigate to the dashboard after successful login and data fetch
          navigate('../dashboard');
        } else {
          console.error('Login failed');
        }
      } catch (error) {
        console.error('Error logging in:', error);
      }
    };
    
    const fetchUserDetails = async (token) => {
      try {
        const response = await axios.post(
          "http://68.178.169.226:12100/api/v1/login/getuserdetails",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
    
        if (response.status === 200) {
          const { id, firstname, email, role } = response.data;
    
          // Store user details in localStorage
          localStorage.setItem("id", id);
          localStorage.setItem("firstname", firstname);
          localStorage.setItem("email", email);
          localStorage.setItem("role", role);
    
          // Set user data state (if needed for the component)
          setUserData(response.data);
        } else {
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    
  return (
    <div className="accountbg">
      <div className="login-center">
        <div className="login-wrappere">
          <div className="test">
        {/*<img src={logoUrl} alt="Logo" width='100%'/>*/}
            <h4 className="log_cap">Sign in to continue to Login.</h4>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleLogin}
          >
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                value={mobile}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="login-form-button"
                style={{ width: "100%" }}
              >
                Log in
              </Button>
            </Form.Item>

            <Form.Item>
              <Link to="/forgotPassword" className="login-form-forgot">
                Forgot password
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
