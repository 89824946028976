import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import "./ekka.scss";
import SideBar from "./sidebar";
import { Card, Row, Col, Input, Spin } from "antd";
import Header from "./Header";

const Profile = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!token) {
      navigate("/AdminLogin");
      return;
    }
    fetchUserData();
    fetchData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.post(
        "http://68.178.169.226:12100/api/v1/login/getuserdetails",
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      setUserData(response.data);
    } catch {
      console.log("Error in fetching user data");
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "http://68.178.169.226:12100/api/v1/user/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const currentUserProfile = data.find((user) => user.id === userData.id);

  return (
    <div className="wrapper">
      <SideBar />
      <Header />
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          {loading ? (
            <div style={{ textAlign: "center", paddingTop: "50px" }}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12 d-flex justify-content-between">
                    <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
                      <h1>PROFILE</h1>
                    </div>
                    <input
                      className="input-box"
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mt-2">
                  <div className="ec-cat-list card card-default">
                    <div className="card-body">
                      {/* Modern Profile Layout */}
                      {currentUserProfile ? (
                        <Card title={`${currentUserProfile.firstname} ${currentUserProfile.lastname || ""}`}>
                          <Row gutter={16}>
                            <Col span={12}>
                              <h3 className="profile-key">Email:</h3>
                              <p>{currentUserProfile.email}</p>
                            </Col>
                            <Col span={12}>
                              <h3 className="profile-key">Mobile:</h3>
                              <p>{currentUserProfile.mobile}</p>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <h3 className="profile-key">Date of Birth:</h3>
                              <p>{currentUserProfile.dob}</p>
                            </Col>
                            <Col span={12}>
                              <h3 className="profile-key">Address:</h3>
                              <p>{currentUserProfile.address}</p>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <h3 className="profile-key">Aadhar Number:</h3>
                              <p>{currentUserProfile.aadharcardnumber}</p>
                            </Col>
                            <Col span={12}>
                              <h3 className="profile-key">Gender:</h3>
                              <p>{currentUserProfile.gender}</p>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={12}>
                              <h3 className="profile-key">Role:</h3>
                              <p>{currentUserProfile.role}</p>
                            </Col>
                            <Col span={12}>
                              <h3 className="profile-key">Created On:</h3>
                              <p>{new Date(currentUserProfile.createDate).toLocaleDateString()}</p>
                            </Col>
                          </Row>
                        </Card>
                      ) : (
                        <p>No profile data found.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
