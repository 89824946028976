import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./style.scss";
import "./ekka.scss";
import SideBar from "./sidebar";
import { Input, Form, Upload, Button, Modal, DatePicker, Spin } from "antd";
import Header from "./Header";
import EditCategoryModal from "./EditCategoryModal";
import { Select } from "antd";
const { Option } = Select;

const menuItems = [
  { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
];

const MainCategory = () => {
  const token = localStorage.getItem("token");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [mpAreaList, setMpAreaList] = useState([]);
  const [mlaAreaLists, setMlaAreaLists] = useState([]);
  const [selectedAge, setSelectedAge] = useState(null);
  const [selectedMp, setSelectedMp] = useState(null);
  const [selectedMla, setSelectedMla] = useState(null);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setShowEditModal(true);
    setViewOnly(false);
  };
  const handleViewCategory = (category) => {
    setSelectedCategory(category);
    setShowEditModal(true);
    setViewOnly(true);
  };
  const handleCloseEditModal = () => {
    setSelectedCategory(null);
    setShowEditModal(false);
    setViewOnly(false);
  };
  const handleSaveEdit = (editedData) => {
    console.log("Edited data:", editedData);
    handleCloseEditModal();
  };
  useEffect(() => {
    if (!token) {
      navigate("/AdminLogin");
      return;
    }
    fetchData();
    fetchuserData();
  }, []);

  const fetchuserData = async () => {
    try {
      const response = await axios.post(
        "http://68.178.169.226:12100/api/v1/login/getuserdetails",
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      setUserData(response.data);
    } catch {
      console.log("Error in fetching data");
    }
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "http://68.178.169.226:12100/api/v1/member/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      const clientIds = response.data.map((client) => client.clientid);
      localStorage.setItem("clientIds", JSON.stringify(clientIds));
      setData(response.data);
      setFilteredData(response.data);
      setCompanyCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const filteredResults = data.filter(
      (item) =>
        (selectedCategory ? item.companycategory === selectedCategory : true) &&
        ((item?.fathername ?? "")
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
          (item?.firstname ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (item?.dob ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
          (item?.address ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
          (item?.city ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (item?.state ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (item?.pincode ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (item?.mparea ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (item?.mlaarea ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (item?.gender ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (item?.occupation ?? "").toLowerCase().includes(searchQuery.toLowerCase()) ||
          (item?.mobile ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          (item?.spousename ?? "")
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))
    );
    setFilteredData(filteredResults);
  }, [searchQuery, data, selectedCategory]);
  const getRowBackgroundColor = (refereddetails) => {
    if (
      refereddetails &&
      refereddetails.some((detail) => detail.accepted === true)
    ) {
      return "#b0e57c";
    }
    return "";
  };

  const handleDeleteCategory = (categoryId) => {
    setSelectedCategory(categoryId);
    setShowDeleteModal(true);
  };

  const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
    return (
      <Modal
        visible={visible}
        title="Confirm Deletion"
        onCancel={onCancel}
        footer={[
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" onClick={onConfirm}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this pincode?</p>
      </Modal>
    );
  };

  const handleDeleteCategoryConfirmed = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `http://68.178.169.226:12100/api/v1/member/delete`,
        {
          id: selectedCategory,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        window.location.reload();
      } else {
        console.error("Failed to delete category");
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };
  useEffect(() => {
    const fetchMpArea = async () => {
      try {
        const response = await axios.get(
          "http://68.178.169.226:12100/api/mp-areas/getall",
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 401 || response.status === 404) {
          navigate("/AdminLogin");
          return;
        }
        setMpAreaList(response.data);
      } catch (error) {
        console.error("Error fetching MP Areas:", error);
      }
    };
    const fetchMlaAreas = async () => {
      try {
        const response = await axios.get(
          "http://68.178.169.226:12100/mla-areas/getall",
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 401 || response.status === 404) {
          navigate("/AdminLogin");
          return;
        }
        setMlaAreaLists(response.data);
      } catch (error) {
        console.error("Error fetching MP Areas:", error);
      }
    };
    if (token) {
      fetchMpArea();
      fetchMlaAreas();
    } else {
      navigate("/AdminLogin");
    }
  }, [token, navigate]);
  const handleAgeChange = async (e) => {
    setSelectedAge(e.target.value);
  };
  const handleMpChange = async (e) => {
    setSelectedMp(e.target.value);
  };
  const handleMlaChange = async (e) => {
    setSelectedMla(e.target.value);
  };
  const handleOccupationChange = async (e) => {
    setSelectedOccupation(e.target.value);
  };
  useEffect(() => {
    applyFilters(selectedAge, selectedMp, selectedMla, selectedOccupation);
  }, [selectedAge, selectedMp, selectedMla, selectedOccupation]);
  const applyFilters = async (isAbove18, mparea, mlaarea, occupation) => {
    try {
      const filterData = {
        isAbove18: isAbove18 || null,
        occupation: occupation || null,
        mparea: mparea || null,
        mlaarea: mlaarea || null,
      };
      const response = await axios.post("http://68.178.169.226:12100/api/v1/member/filterAll", filterData, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });
      setFilteredData(response.data);
    } catch (error) {
    }
  };
  const processedOccupations = Array.from(
    new Set(data.map((job) => job.occupation.trim().toLowerCase()))
  ).map((occupation) =>
    data.find((job) => job.occupation.trim().toLowerCase() === occupation)
  );
  const handleDownload = async () => {
    try {
      const params = new URLSearchParams();
      if (selectedAge) {
        params.append('isAbove18', selectedAge);
      }
      if (selectedMp) {
        params.append('mparea', selectedMp);
      }
      if (selectedMla) {
        params.append('mlaarea', selectedMla);
      }
      if (selectedOccupation) {
        params.append('occupation', selectedOccupation);
      }
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `http://68.178.169.226:12100/api/v1/report/export-member?${params.toString()}`,
        {
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
          responseType: 'blob', 
        }
      );
      downloadCSV(response.data, 'voter_report.csv');
    } catch (error) {
      console.error("Error in downloading the file:", error);
    }
  };
  const downloadCSV = (blobData, fileName) => {
    const url = window.URL.createObjectURL(new Blob([blobData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link); 
  };

  return (
    <div className="wrapper">
      <SideBar />
      <Header />
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          {loading ? (
            <div style={{ textAlign: "center", paddingTop: "50px" }}>
              <Spin size="large" />
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12 d-flex justify-content-between">

                    <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
                      <h1>VOTERS</h1>
                    </div>
                    <button onClick={handleDownload} className="filter-dropdown">Download</button>
                    <select
                      className='filter-dropdown'
                      onChange={handleAgeChange}
                    >
                      <option value="">Select Age</option>
                      <option value="true">Above 18</option>
                      <option value="false">Below 18</option>
                    </select>
                    <select
                      className="filter-dropdown"
                      onChange={handleMpChange}
                    >
                      <option value="">All MP Area</option>
                      {mpAreaList.map((area) => (
                        <option key={area.mpid} value={area.area}>
                          {area.area}
                        </option>
                      ))}
                    </select>
                    <select
                      className="filter-dropdown"
                      onChange={handleMlaChange}
                    >
                      <option value="">All MLA Area</option>
                      {mlaAreaLists.map((area) => (
                        <option key={area.mlaid} value={area.area}>
                          {area.area}
                        </option>
                      ))}
                    </select>
                    <select
                      className="filter-dropdown"
                      onChange={handleOccupationChange}
                    >
                      <option value="">select Occupation</option>
                      {processedOccupations.map((job) => (
                        <option key={job.id} value={job.occupation}>
                          {job.occupation}
                        </option>
                      ))}
                    </select>
                    <input
                      className="input-box"
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <div className="ec-cat-list card card-default">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table id="responsive-data-table" className="table">
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Name</th>
                              <th>Father Name</th>
                              {/* <th>C/O</th> */}
                              {/* <th>Landmark</th> */}
                              <th>Gender</th>
                              <th>Occupation</th>
                              {/* <th>Address</th> */}
                              <th>MP Area</th>
                              <th>MLA Area</th>
                              {/* <th>VTC</th>
                            <th>UID</th> */}
                              {/* <th>Sub District</th> */}
                              {/* <th>District</th> */}
                              <th>State</th>
                              <th>Aadhaar No</th>
                              {/* <th>Location</th>
                            <th>Street</th> */}
                              {/* <th>Year of Birth </th> */}
                              <th>Date of birth</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(data) &&
                              filteredData.map((category,index) => (
                                <tr key={category.id}>
                                  <td>{index+1}</td>
                                  <td>{category.firstname}</td>
                                  <td>{category.fathername}</td>
                                  {/* <td>{category.co}</td> */}
                                  {/* <td>{category.lm}</td> */}
                                  <td>{category.gender}</td>
                                  <td>{category.occupation}</td>
                                  {/* <td>{category.address}</td> */}
                                  <td>{category.mparea}</td>
                                  <td>{category.mlaarea}</td>
                                  { /*<td>{category.vtc}</td>
                                <td>{category.uid}</td> */}
                                  {/* <td>{category.subdist}</td> */}
                                  {/* <td>{category.dist}</td> */}
                                  <td>{category.state}</td>
                                  {/* <td>{category.pc}</td> */}
                                  {/* <td>{category.loc}</td> */}
                                  {/* <td>{category.street}</td> */}
                                  {/* <td>{category.yob}</td> */}
                                  <td>{category.uid}</td>
                                  <td>{category.dob}</td>
                                  <td>
                                    <div className="btn-group">
                                      <a
                                        href="#"
                                        className="btn btn-outline-primary"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleViewCategory(category);
                                        }}
                                      >
                                        View
                                      </a>
                                      <a
                                        href="#"
                                        className="btn btn-outline-success"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleEditCategory(category);
                                        }}
                                      >
                                        Edit
                                      </a>
                                      {userData.role == "SUPERADMIN" && (
                                        <a
                                          href="#"
                                          className="btn btn-outline-danger"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleDeleteCategory(category.id);
                                          }}
                                        >
                                          Delete
                                        </a>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {selectedCategory && (
        <EditCategoryModal
          category={selectedCategory}
          show={showEditModal}
          onHide={handleCloseEditModal}
          onSave={handleSaveEdit}
          viewOnly={viewOnly}
        />
      )}
      {selectedCategory && (
        <DeleteCategoryModal
          visible={showDeleteModal}
          onCancel={() => {
            setShowDeleteModal(false);
            window.location.reload();
          }}
          onConfirm={handleDeleteCategoryConfirmed}
        />
      )}
    </div>
  );
};

export default MainCategory;
